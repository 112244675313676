<template>
  <ClientOnly>
    <a
      ref="wrapper"
      href="https://twitter.com/share"
      class="twitter-share-button"
      data-lang="ja"
    >
      ツイート
    </a>
  </ClientOnly>
</template>

<script setup lang="ts">
const wrapper = ref<HTMLElement>();

onMounted(async () => {
  // ref: https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-loading-and-initialization
  await nextTick();
  if (window.twttr) {
    window.twttr.widgets.load(wrapper.value);
  }
});
</script>
